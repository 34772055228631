<template>
  <div
    v-infinite-scroll="loadMore"
    infinite-scroll-disabled="busy"
    infinite-scroll-distance="10"
  >
    <r-page page-title="Allowance Category">
      <template #page-header>
        <h2 class="page-title">Allowance Setting</h2>
        <r-menu-tab :level="3" />
      </template>
      <template #action-bar>
        <div class="level">
          <div class="level-left">
            <b-button
              tag="button"
              class="is-command"
              icon-left="plus"
              @click.native="openModalCreate()"
            >
              New
            </b-button>
          </div>
        </div>
      </template>

      <template #page-content>
        <div id="table-allowance-type" class="table-timeoff-type">
          <b-table
            :data="allowanceList"
            :per-page="perPage"
            :loading="isAllowanceTypeListLoading"
            @page-change="onPageChange"
            :show-detail-icon="showDetailIcon"
            ref:table
            hoverable
            narrowed
            backend-sorting
            :default-sort-direction="defaultSortOrder"
            :default-sort="[sortField, sortOrder]"
            checkbox-position="left"
            class="employment-directory-table"
            @sort="onSort"
            :sticky-header="stickyHeaders"
          >
            <template>
              <b-table-column label="No" v-slot="props" width="5%">
                <span class="is-capitalize">
                  {{ props.index + 1 }}
                </span>
              </b-table-column>
              <b-table-column
                field="name"
                label="Allowance Name"
                v-slot="props"
                sortable
                width="67%"
              >
                <span class="is-capitalize">{{ props.row.name }}</span>
              </b-table-column>
              <b-table-column label="Action" v-slot="props" width="8%">
                <b-icon
                  type="is-primary"
                  icon="pencil"
                  custom-size="mdi-18px"
                  class="employment-edit-icon"
                  @click.native="openModalCreate(props.row)"
                ></b-icon>
                <b-icon
                  type="is-primary"
                  icon="delete"
                  custom-size="mdi-18px"
                  class="timeoff-type-delete-icon"
                  @click.native="deletionWarning(props.row.id)"
                ></b-icon>
              </b-table-column>
            </template>
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>No data found</p>
                </div>
              </section>
            </template>
          </b-table>
        </div>

        <r-page-modal
          :prompt-on-leave="false"
          prompt-message="Are you sure?"
          v-if="isModalOpen"
          @hide="closeModalCreate"
          :is-modal="true"
          class="modal-allowance-type"
        >
          <template #page-content>
            <h3 class="modal-title has-text-weight-black">
              {{
                isEditing
                  ? 'Edit Allowance Category'
                  : 'Create New Allowance Category'
              }}
            </h3>
            <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
              <form
                @submit.prevent="handleSubmit(submit)"
                class="is-label-14-px-normal form-timeoff-type"
              >
                <div class="columns is-multiline">
                  <div class="column is-12">
                    <ValidationProvider
                      name="Allowance Name"
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <b-field
                        label="Allowance Name"
                        :type="errors[0] && 'is-danger'"
                        :message="errors[0]"
                      >
                        <b-input
                          v-model="data.name"
                          placeholder="Allowance Name"
                        ></b-input>
                      </b-field>
                    </ValidationProvider>
                  </div>
                  <div class="column">
                    <b-button
                      native-type="submit"
                      type="is-primary"
                      expanded
                      :loading="isSubmitting"
                    >
                      {{ isEditing ? 'Submit' : 'Add New' }}
                    </b-button>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </template>
        </r-page-modal>
      </template>
    </r-page>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { showToast } from '@/services/util'

export default {
  data() {
    return {
      stickyHeaders: true,
      isPaginated: false,
      perPage: 20,
      page: 0,
      lastPage: 0,

      defaultSortOrder: 'desc',
      sortField: 'name',
      sortOrder: 'desc',
      search: '',
      showDetailIcon: true,

      isAllowanceTypeListLoading: false,
      isLoadMore: false,
      isModalOpen: false,
      isEditing: false,

      data: {
        id: '',
        name: null,
        createdAt: null,
        updatedAt: null,
      },
      isSubmitting: null,
    }
  },
  computed: {
    ...mapGetters({
      allowanceList: 'allowanceModule/getAllowanceList',
    }),
  },
  methods: {
    ...mapActions({
      actionLoadAllowanceList: 'allowanceModule/getAllowanceList',
      saveAllowanceType: 'allowanceModule/saveAllowanceType',
      editAllowanceType: 'allowanceModule/editAllowanceType',
      deleteAllowanceType: 'allowanceModule/deleteAllowanceType',
    }),

    ...mapMutations({
      setAllowanceList: 'allowanceModule/setAllowanceList',
    }),

    async loadAllowanceTypeList() {
      this.isAllowanceTypeListLoading = true
      try {
        const response = await this.actionLoadAllowanceList({
          perPage: this.perPage,
          page: ++this.page,
          sortField: this.sortField,
          sortOrder: this.sortOrder,
        })

        this.total = response.data.total
        this.lastPage = response.data.lastPage
      } catch (err) {
        console.log(err)
      }
      this.isAllowanceTypeListLoading = false
    },

    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.resetState()
    },

    onPageChange(page) {
      this.page = page
      this.loadAllowanceTypelist()
    },

    async resetState() {
      this.page = 0
      this.lastPage = 0
      this.setAllowanceList([])
      await this.loadAllowanceTypelist()
    },

    async loadMore() {
      if (this.page < this.lastPage) {
        this.isLoadMore = true
        await this.loadAllowanceTypelist()
        this.isLoadMore = false
      }
    },

    openModalCreate(row) {
      if (row) {
        this.isEditing = true
        this.data = {
          id: row.id,
          name: row.name,
        }
      }
      this.isModalOpen = true
    },

    closeModalCreate() {
      this.resetForm()
      this.isModalOpen = false
      this.isEditing = false
    },

    resetForm() {
      this.data = {
        id: '',
        name: null,
      }
    },

    async submit() {
      this.isSubmitting = true
      let form = new FormData()

      try {
        if (!this.isEditing) {
          form.append('name', this.data.name)

          await this.saveAllowanceType(form)
          showToast('Data is successfully added!', 'is-success', 'is-bottom')
        } else {
          await this.editAllowanceType(this.data)
          showToast('Data is successfully edited!', 'is-success', 'is-bottom')
        }
      } catch (e) {
        showToast(e.response.message, 'is-danger', 'is-bottom')
      }

      this.isSubmitting = false
      this.isEditing = false

      this.resetForm()
      this.isModalOpen = false
    },

    deletionWarning(id) {
      this.$swal({
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Continue',
        denyButtonText: 'No',
        inputValue: 1,
        customClass: {
          confirmButton: 'button is-primary',
          denyButton: 'button is-primary',
          cancelButton: 'button',
        },
        text: 'Are you sure you want to delete this data?',
      }).then(async (res) => {
        try {
          if (res && res.isConfirmed) {
            await this.deleteAllowanceType(id)

            this.$swal({
              icon: 'success',
              titleText: 'Data is successfully deleted!',
              confirmButtonText: 'Done',
              customClass: {
                confirmButton: 'button is-success',
              },
            })
          }
        } catch (e) {
          this.$swal({
            icon: 'error',
            titleText: 'Failed. Please try again!',
            confirmButtonText: 'Go Back Home',
            customClass: {
              confirmButton: 'button is-grey',
            },
          })
        }
      })
    },
  },
  async mounted() {
    this.setAllowanceList([])
    await this.loadAllowanceTypeList()
  },
}
</script>
